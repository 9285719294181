import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import './SurgeryConsent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faPause, faStop, faPlay, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SignatureCanvas from 'react-signature-canvas';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const fetchConsents = async (hash_value) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/surgery-consent/${hash_value}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching consents data:", error);
    return null;
  }
};


const fetchPatientData = async (hash_value) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/patients/${hash_value}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching HIPAA waiver consent data:", error);
    return null;
  }
};

function SurgeryConsent() {
  const { hash_value } = useParams();
  const [consents, setConsents] = useState({ hipaaConsent: null, surgeryConsent: null });
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState(null);
  const [otp, setOtp] = useState('');
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [speechState, setSpeechState] = useState({
    utterance: null,
    isPlaying: false
  });
  const [form, setForm] = useState({
    approverName: '',
    relationship: '',
    approverSignature: '',
    witnessName: '',
    witnessSignature: ''
  });
  const approverSignatureRef = useRef(null);
  const witnessSignatureRef = useRef(null);

  useEffect(() => {
    const getConsents = async () => {
      const data = await fetchConsents(hash_value);
      const patientData = await fetchPatientData(hash_value);
      setConsents(data);
      setPatient(patientData);
      setLoading(false);
    };
    getConsents();
  }, [hash_value]);

  const readConsent = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      setSpeechState({ utterance, isPlaying: true });
      utterance.onend = () => setSpeechState({ utterance: null, isPlaying: false });
      speechSynthesis.speak(utterance);
    } else {
      console.error("Speech Synthesis not supported in this browser.");
    }
  };

  const handlePause = () => {
    if ('speechSynthesis' in window && speechSynthesis.speaking) {
      speechSynthesis.pause();
      setSpeechState(prevState => ({ ...prevState, isPlaying: false }));
    }
  };

  const handleResume = () => {
    if ('speechSynthesis' in window && speechSynthesis.paused) {
      speechSynthesis.resume();
      setSpeechState(prevState => ({ ...prevState, isPlaying: true }));
    }
  };

  const handleStop = () => {
    if ('speechSynthesis' in window && (speechSynthesis.speaking || speechSynthesis.paused)) {
      speechSynthesis.cancel();
      setSpeechState({ utterance: null, isPlaying: false });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };

  const sendOtp = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/twilio/send-otp/${hash_value}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
      	setOtp('');  // Clear the OTP input field
        setShowOtpPopup(true); // Show OTP popup if OTP sent successfully
      } else {
        toast.error('Failed to send OTP.');
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  
  const handleApproveConsent = (e) => {
    e.preventDefault();

    let isValid = true;

    if (approverSignatureRef.current.isEmpty()) {
      setForm((prevForm) => ({
        ...prevForm,
        approverSignatureError: "Please provide a signature."
      }));
      isValid = false;
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        approverSignatureError: ""
      }));
    }

    if (witnessSignatureRef.current.isEmpty()) {
      setForm((prevForm) => ({
        ...prevForm,
        witnessSignatureError: "Please provide a signature."
      }));
      isValid = false;
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        witnessSignatureError: ""
      }));
    }

    if (!isValid) {
      return;
    }

    sendOtp();  // Send OTP after form validation
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/twilio/verify-otp/${hash_value}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ otpCode: otp })  // Send the OTP entered by the user
      });

      const result = await response.json();

      if (response.ok && result.message === 'OTP verified successfully.') {
        const approverSignatureData = approverSignatureRef.current.getTrimmedCanvas().toDataURL('image/png');
        const witnessSignatureData = witnessSignatureRef.current.getTrimmedCanvas().toDataURL('image/png');

        const formData = {
          ...form,
          approverSignature: approverSignatureData,
          witnessSignature: witnessSignatureData
        };

        const response = await fetch(`${apiBaseUrl}/api/surgery-consent/approve-surgery-consent/${hash_value}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
      
        if (response.ok) {
          toast.success('Consent approved successfully.', {
            onClose: () => {
              window.location.reload();  // Reload the page after the toast closes
            }
          });
        } else {
          toast.error('Failed to approve consent.');
        }
      } else {
        toast.error(result.message || 'Invalid OTP.');
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error('Error verifying OTP.');
    }

    setShowOtpPopup(false);  // Hide OTP popup after verification
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!consents.hipaaConsent || !consents.surgeryConsent) {
    return <div>No consent data found.</div>;
  }

  const { is_hipaa_acknowledge } = consents.hipaaConsent;
  const { is_email_sent, date_when_sent, surgery_consent, is_surgery_acknowledge } = consents.surgeryConsent;

  const surgerySentDate = moment(date_when_sent);
  const currentDate = moment();
  const surgeryDaysDifference = currentDate.diff(surgerySentDate, 'days');

  if (!is_hipaa_acknowledge) {
    return <div>HIPAA consent not provided. Please provide HIPAA consent first.</div>;
  }

  if (is_surgery_acknowledge) {
    return <div>Surgery consent already provided.</div>;
  }

  if (surgeryDaysDifference > 30) {
    return <div>Link expired. Please contact your doctor.</div>;
  }

  if (is_email_sent && surgeryDaysDifference <= 30 && !is_surgery_acknowledge) {
    return (
      <div className="consent-container">
        <h1>Surgery Consent Details</h1>
        <div className="consent-details">
          {surgery_consent.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        {patient?.surgeon_signature && (
            <div className="signature-section">
              <p/>
              <label htmlFor="surgeonSignature">Surgeon's Signature</label>
              <p/>
              <img
                src={patient.surgeon_signature}
                alt="Surgeon Signature"
                style={{ width: '300px', height: '120px', border: '1px solid #ccc' }}
              />
            </div>
          )}
        <div className="consent-actions">
          <button onClick={() => readConsent(surgery_consent)}>
            <FontAwesomeIcon icon={faVolumeHigh} /> Read Consent
          </button>
          {speechState.isPlaying && (
            <button onClick={handlePause}>
              <FontAwesomeIcon icon={faPause} /> Pause
            </button>
          )}
          {!speechState.isPlaying && speechState.utterance && (
            <button onClick={handleResume}>
              <FontAwesomeIcon icon={faPlay} /> Resume
            </button>
          )}
          {speechState.utterance && (
            <button onClick={handleStop}>
              <FontAwesomeIcon icon={faStop} /> Stop
            </button>
          )}
        </div>
        <form className="consent-form" onSubmit={handleApproveConsent}>
          <div className="form-group">
            <label htmlFor="approverName">Name:</label>
            <input
              type="text"
              id="approverName"
              name="approverName"
              value={form.approverName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="relationship">Relationship with Patient:</label>
            <input
              type="text"
              id="relationship"
              name="relationship"
              value={form.relationship}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Signature (Approver/Patient Representative):</label>
            <div className="signature-container">
              <SignatureCanvas
                ref={approverSignatureRef}
                penColor="black"
                canvasProps={{ width: 300, height: 120, className: 'sigCanvas' }}
              />
              {form.approverSignatureError && (
                <div className="error">
                  <FontAwesomeIcon icon={faExclamationTriangle} color="#ff8c00" /> {form.approverSignatureError}
                </div>
              )}
              <button type="button" onClick={() => approverSignatureRef.current.clear()}>Clear</button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="witnessName">Witness Name:</label>
            <input
              type="text"
              id="witnessName"
              name="witnessName"
              value={form.witnessName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Witness Signature:</label>
            <div className="signature-container">
              <SignatureCanvas
                ref={witnessSignatureRef}
                penColor="black"
                canvasProps={{ width: 300, height: 120, className: 'sigCanvas' }}
              />
              {form.witnessSignatureError && (
                <div className="error">
                  <FontAwesomeIcon icon={faExclamationTriangle} color="#ff8c00" /> {form.witnessSignatureError}
                </div>
              )}
              <button type="button" onClick={() => witnessSignatureRef.current.clear()}>Clear</button>
            </div>
          </div>
          <div className="button-container">
            <button type="submit">Approve Consent</button>
          </div>
        </form>

        {/* OTP Verification Popup */}
        {showOtpPopup && (
          <div className="otp-popup">
            <div className="otp-popup-overlay" onClick={() => setShowOtpPopup(false)}></div>
            <div className="otp-popup-content">
              <h2>Enter OTP</h2>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <div className="otp-popup-buttons">
                <button onClick={handleVerifyOtp}>Verify</button>
                <button onClick={() => setShowOtpPopup(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }

  return <div>Invalid request.</div>;
}

export default SurgeryConsent;
