import React from "react";
import Slider from "react-slick";
import "./Carousel.css"; // Ensure you have this CSS file

function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div className="slide">
          <h1>Secure Digital Consents</h1>
          <p>Ensure HIPAA-compliant consent collection with our platform.</p>
        </div>
        <div className="slide">
        <img src="https://img.freepik.com/free-vector/gradient-halftone-technology-twitch-banner_23-2149195837.jpg"/>
        </div>
        <div className="slide">
        <h1>AI-Powered Insights</h1>
          <p>Track and analyze patient engagement with our smart analytics.</p>
        </div>
      </Slider>
    </div>
  );
}

export default Carousel;
