import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import states from './data/states'; // Import the states array
import './GenerateConsent.css';
import { toast, ToastContainer } from 'react-toastify';
import SignatureCanvas from 'react-signature-canvas';
import 'react-toastify/dist/ReactToastify.css';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const GenerateConsent = () => {
  useAuth();
  const location = useLocation();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const prefilledData = location.state?.consent || {};
  const initialFormState = {
    firstName: prefilledData.Patient?.first_name || '',
    lastName: prefilledData.Patient?.last_name || '',
    dateOfBirth: prefilledData.Patient?.date_of_birth || '',
    gender: prefilledData.Patient?.gender || '',
    addressLine1: prefilledData.Patient?.address_line1 || '',
    addressLine2: prefilledData.Patient?.address_line2 || '',
    state: prefilledData.Patient?.state || '',
    phoneNumber: prefilledData.Patient?.phone_number || '+1',
    email: prefilledData.Patient?.email_id || '',
    surgeryType: prefilledData.Patient?.surgery_type || '',
    surgeryConsent: prefilledData.surgery_consent || '',
    hipaaConsent: prefilledData.hipaa_consent || '',
    consentVerifiedBySurgeon: false,  // New field for the checkbox
    surgeonSignature: ''
  };

  const [form, setForm] = useState(initialFormState);
  const [surgeries, setSurgeries] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isHIPAAButtonEnabled, setIsHIPAAButtonEnabled] = useState(false);
  const [isSurgeryButtonEnabled, setIsSurgeryButtonEnabled] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoadingHipaaConsent, setIsLoadingHipaaConsent] = useState(false);
  const [isLoadingSurgeryConsent, setIsLoadingSurgeryConsent] = useState(false);  
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedSurgeryId, setSelectedSurgeryId] = useState('');
  const fileInputRef = useRef(null);
  const signatureRef = useRef(null);
  const [surgeonSignature, setSurgeonSignature] = useState('');



  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  useEffect(() => {
    const fetchSurgeries = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/api/surgeries/active`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          setSurgeries(data);
        } else {
          setSurgeries([]);
        }
      } catch (error) {
        console.error('Error fetching surgeries:', error);
        setSurgeries([]);
      }
    };
    fetchSurgeries();
  }, []);

  useEffect(() => {
    validateForm();
  }, [form]);

  const validateForm = () => {
    const isHIPAAValid = [
      form.firstName,
      form.lastName,
      form.dateOfBirth,
      form.gender,
      form.addressLine1,
      form.state,
      form.phoneNumber,
      form.email,
      form.surgeryType,
    ].every(value => value !== '');

    const isSurgeryValid = isHIPAAValid && form.hipaaConsent !== '';

    const isSubmitValid =
      isSurgeryValid &&
      form.surgeryConsent !== '' &&
      form.consentVerifiedBySurgeon &&
      form.surgeonSignature !== '';

    setIsHIPAAButtonEnabled(isHIPAAValid);
    setIsSurgeryButtonEnabled(isSurgeryValid);
    setIsSubmitButtonEnabled(isSubmitValid);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSignatureEnd = () => {
    const signatureData = signatureRef.current.toDataURL(); // Get signature as Base64
    setForm((prevForm) => ({
      ...prevForm,
      surgeonSignature: signatureData, // Save signature data
      surgeonSignatureError: '', // Clear any previous error
    }));
  };
  
  

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to array
    const pdfFiles = selectedFiles.filter(file => file.type === "application/pdf");
  
    if (pdfFiles.length !== selectedFiles.length) {
      toast.error("Only PDF files are allowed! Non-PDF files have been removed.");
    }
  
    // Create a new DataTransfer object to update input files
    const dataTransfer = new DataTransfer();
    pdfFiles.forEach(file => dataTransfer.items.add(file));

    e.target.files = dataTransfer.files; // Update input field with valid files
    setDocuments(pdfFiles); // Update state with only valid PDF files
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Date of Birth Validation
    if (new Date(form.dateOfBirth) > new Date()) {
      toast.error("Date of birth cannot be in the future.");
      return;
    }

    // Phone Number Validation
    if (!/^\+?[1-9]\d{1,14}$/.test(form.phoneNumber)) {
     toast.error("Invalid phone number format.");
     return;
    }

    // Email Validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      toast.error("Invalid email format.");
      return;
    }

    if (signatureRef.current.isEmpty()) {
      setForm((prevForm) => ({
        ...prevForm,
        surgeonSignatureError: "Please provide a signature."
      }));
      return;
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        surgeonSignatureError: ""
      }));
    }

    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    Array.from(documents).forEach((file) => {
      formData.append('documents', file);
    });

    const surgeonSignatureData = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    setSurgeonSignature(surgeonSignatureData);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/patients`, {
        method: 'POST',
        headers: {
          'x-auth-token': token,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success('Consent form submitted successfully');
        setForm(initialFormState);  // Clear the form
        setDocuments([]);  // Clear the documents state
        signatureRef.current.clear(); // Clear canvas after submission
        if (fileInputRef.current) {
          fileInputRef.current.value = '';  // Clear the file input
        }
      } else {
        toast.error('Failed to submit patient or consents');
      }
    } catch (error) {
      console.error('Error submitting consent form:', error);
    }
  };

  const fetchTemplates = async (surgeryId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/templates?surgeryId=${surgeryId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setTemplates(data);
      } else {
        setTemplates([]);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      setTemplates([]);
    }
  };

  const handleSurgeryChange = async (e) => {
    const surgeryName = e.target.value;
    const surgeryId = e.target.selectedOptions[0].getAttribute('data-id'); // Use the data-id attribute to get the surgeryId
    setForm({
      ...form,
      surgeryType: surgeryName,
      surgeryConsent: '' // Clear the consent when surgery changes
    });
    setSelectedSurgeryId(surgeryId);

    if (surgeryId) {
      await fetchTemplates(surgeryId);
    } else {
      // Reset the template form if no valid surgery is selected
      setTemplates([]);
      setSelectedTemplate('');
      setForm(prevForm => ({ ...prevForm, surgeryConsent: '' }));
    }
  };

  const handleTemplateChange = (e) => {
    const selectedTemplateId = e.target.value;
    const template = templates.find(template => template.id === parseInt(selectedTemplateId));
    if (template) {
      setForm(prevForm => ({ ...prevForm, surgeryConsent: template.consent, consentVerifiedBySurgeon: true, }));
    } else {
      setForm(prevForm => ({ ...prevForm, surgeryConsent: '' , consentVerifiedBySurgeon: false, }));
    }
    setSelectedTemplate(selectedTemplateId);
  };

  const generateHipaaConsent = async () => {
    setIsLoadingHipaaConsent(true);  // Start loading
    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/consents/generate-hipaa-consent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(form),
      });

      const data = await response.json();
      setForm(prevForm => ({ ...prevForm, hipaaConsent: data.consent }));
    } catch (error) {
      console.error('Error generating HIPAA consent:', error);
      toast.error('Failed to generate HIPAA consent');
    } finally {
      setIsLoadingHipaaConsent(false);  // Stop loading
    }
  };

  const generateSurgeryConsent = async () => {
    setIsLoadingSurgeryConsent(true);  // Start loading
    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/consents/generate-surgery-consent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(form),
      });

      const data = await response.json();
      setForm(prevForm => ({ ...prevForm, surgeryConsent: data.consent }));
    } catch (error) {
      console.error('Error generating Surgery consent:', error);
      toast.error('Failed to generate Surgery consent');
    } finally {
      setIsLoadingSurgeryConsent(false);  // Stop loading
    }
  };

  return (
    <div className="consent-form-container">
      <h2>PATIENT'S INFORMATION</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Form fields */}
        <div className="form-group">
          <label>First Name</label>
          <label>Last Name</label>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            required
            placeholder="First Name"
          />
          <input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            required
            placeholder="Last Name"
          />
        </div>
        <div className="form-group">
          <label>Date of Birth</label>
          <label>Gender</label>
        </div>
        <div className="form-group">
          <input
            type="date"
            name="dateOfBirth"
            value={form.dateOfBirth}
            onChange={handleChange}
            required
          />
          <select name="gender" value={form.gender} onChange={handleChange} required>
            <option value="">Please Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Address</label>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="addressLine1"
            value={form.addressLine1}
            onChange={handleChange}
            required
            placeholder="Street address"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="addressLine2"
            value={form.addressLine2}
            onChange={handleChange}
            placeholder="Street address line 2"
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <label>Type of Surgery</label>
        </div>
        <div className="form-group">
          <select name="state" value={form.state} onChange={handleChange} required>
            <option value="">State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          <select
            name="surgeryType"
            value={form.surgeryType}
            onChange={handleSurgeryChange}
            required
          >
            <option value="">Select</option>
            {surgeries.map((surgery) => (
              <option key={surgery.id} value={surgery.name} data-id={surgery.id}>
                {surgery.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <label>Email</label>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="phoneNumber"
            value={form.phoneNumber || '+1'}
            onChange={handleChange}
            required
            placeholder="(000) 3202 0102"
          />
          <input
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
            placeholder="example@example.com"
          />
        </div>
        <div className="form-group">
          <label>HIPAA Waiver Consent</label>
        </div>
        <div className="form-group">
          <textarea
            name="hipaaConsent"
            value={form.hipaaConsent}
            onChange={handleChange}
            required
            disabled={isLoadingHipaaConsent}  // Disable textarea when loading
          ></textarea>
        </div>
        <div className="button-container">
        <button
          type="button"
          onClick={generateHipaaConsent}
          disabled={!isHIPAAButtonEnabled || isLoadingHipaaConsent}
        >
          {isLoadingHipaaConsent ? (
            <>
              <i className="fa fa-refresh fa-spin"></i> Generating HIPAA Consent...
            </>
          ) : (
            "Generate HIPAA Consent"
          )}
        </button>
        </div>
        <div className="form-group">
          <label>Surgery Consent</label>
        </div>
        <div className="form-group">
          <select
            name="selectedTemplate"
            value={selectedTemplate}
            onChange={handleTemplateChange}
            disabled={!isSurgeryButtonEnabled || isLoadingSurgeryConsent || templates.length === 0}
          >
            <option value="">Select a Template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
          <label> </label>
        </div>
        <div className="form-group">
          <label>Surgery Consent</label>
        </div>
        <div className="form-group">
          <textarea
            name="surgeryConsent"
            value={form.surgeryConsent}
            onChange={(e) => {
              setForm({
                ...form,
                surgeryConsent: e.target.value,
                consentVerifiedBySurgeon: false,  // Reset to false when the text area is modified
              });
            }}
            required
            disabled={isLoadingSurgeryConsent}  // Disable textarea when loading
          ></textarea>
        </div>
        
        <div className="button-container">
        <button
          type="button"
          onClick={generateSurgeryConsent}
          disabled={!isSurgeryButtonEnabled || isLoadingSurgeryConsent}
        >
          {isLoadingSurgeryConsent ? (
            <>
              <i className="fa fa-refresh fa-spin"></i> Generating Surgery Consent...
            </>
          ) : (
            "Generate Surgery Consent"
          )}
        </button>
        </div>
        {/* Checkbox for Consent Verified by Surgeon */}
        <div className="form-group">
          <label>Consent verified by Surgeon</label>
        </div>
        <div className="checkbox-container">
          <input
            type="checkbox"
            name="consentVerifiedBySurgeon"
            checked={form.consentVerifiedBySurgeon}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
        <label>Surgeon Signature:</label>
        </div>
        <div className="form-group">
        <div className="signature-container">
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{ width: 300, height: 120, className: 'sigCanvas' }}
            onEnd={handleSignatureEnd} // Trigger when user stops signing
          />
          {form.surgeonSignatureError && (
            <div className="error">
              <FontAwesomeIcon icon={faExclamationTriangle} color="#ff8c00"/> {form.surgeonSignatureError}
            </div>
          )}
          <button 
            type="button" 
            onClick={() => {
              signatureRef.current.clear();
              setForm((prevForm) => ({
                ...prevForm,
                surgeonSignature: "", // Clear signature from state
              }));
            }}
          >Clear
          </button>
        </div>
      </div>
        <div className="form-group">
          <label>Please choose files to upload Supporting Documents (Only PDF files can be uploaded)</label>
        </div>
        <div className="form-group">
          <input
            type="file"
            name="documents"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef} // Attach ref to file input
          />
        </div>
        <div className="button-container">
          <button type="submit" disabled={!isSubmitButtonEnabled}>Submit</button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default GenerateConsent;
