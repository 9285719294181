import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt , faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../hooks/useAuth';
import './SurgeryConsentTemplate.css';

const SurgeryConsentTemplate = () => {
  useAuth();

  const [surgeries, setSurgeries] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState('');
  const [selectedSurgeryId, setSelectedSurgeryId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateConsent, setTemplateConsent] = useState('');
  const [templates, setTemplates] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // For editing existing templates
  const [isGenerating, setIsGenerating] = useState(false); // New state to track button status
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



  let form = {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    dateOfBirth: 'DATE_OF_BIRTH',
    gender: 'GENDER',
    addressLine1: 'ADDRESS_LINE1',
    addressLine2: 'ADDRESS_LINE2',
    state: 'STATE',
    phoneNumber: 'PHONE_NUMBER',
    email: 'EMAIL',
    surgeryType: ''
  };

  useEffect(() => {
    const fetchSurgeries = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/api/surgeries/active`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          setSurgeries(data);
        } else {
          setSurgeries([]);
        }
      } catch (error) {
        console.error('Error fetching surgeries:', error);
        setSurgeries([]);
      }
    };

    fetchSurgeries();
  }, []);

  const fetchTemplates = async (surgeryId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/templates?surgeryId=${surgeryId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setTemplates(data);
      } else {
        setTemplates([]);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      setTemplates([]);
    }
  };

  const handleSurgeryChange = (e) => {
    const surgeryId = e.target.value;
    const surgeryName = e.target.options[e.target.selectedIndex].text;
    setSelectedSurgery(surgeryName);
    setSelectedSurgeryId(surgeryId);

    if (surgeryId) {
      fetchTemplates(surgeryId);
    } else {
      // Reset the template form if no valid surgery is selected
      setTemplates([]);
      setTemplateName('');
      setTemplateConsent('');
      setEditingIndex(null);
    }
  };

  const generateSurgeryConsent = async () => {
    if (selectedSurgery) {
      setIsGenerating(true); // Disable button and change text
      try {
        const token = localStorage.getItem('token');
        form.surgeryType = selectedSurgery; // Set surgery name in form data
        const response = await fetch(`${apiBaseUrl}/api/consents/generate-surgery-consent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          body: JSON.stringify(form),  // Send form data to generate consent
        });

        const data = await response.json();
        setTemplateConsent(data.consent); // Assuming the response has a "consent" field
        setTemplateName('');
        setEditingIndex(null); // Reset editing state
      } catch (error) {
        console.error('Error generating Surgery consent:', error);
      } finally {
        setIsGenerating(false); // Re-enable button and reset text
      }
    } else {
      alert("Please select a surgery to generate a template.");
    }
  };

  const handleSaveTemplate = async () => {
    if (!templateName.trim()) {
      alert("Please enter a template name.");
      return;
    }

    const token = localStorage.getItem('token');
    const templateData = {
      surgeryId: selectedSurgeryId,
      name: templateName,
      consent: templateConsent,
    };

    try {
      let response;
      if (editingIndex !== null) {
        // Update existing template
        const templateId = templates[editingIndex].id;
        response = await fetch(`${apiBaseUrl}/api/templates/${templateId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          body: JSON.stringify(templateData),
        });
      } else {
        // Save new template
        response = await fetch(`${apiBaseUrl}/api/templates`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          body: JSON.stringify(templateData),
        });
      }

      if (response.ok) {
        alert('Template saved successfully!');
        fetchTemplates(selectedSurgeryId);
        setTemplateName('');
        setTemplateConsent('');
        setEditingIndex(null);
      } else {
        console.error('Failed to save template:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleEditTemplate = (index) => {
    const template = templates[index];
    setTemplateName(template.name);
    setTemplateConsent(template.consent);
    setEditingIndex(index);
  };

  const handleCancelEdit = () => {
    setTemplateName('');
    setTemplateConsent('');
    setEditingIndex(null);
  };

  const handleToggleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/api/templates/${id}/toggle-delete`, {
        method: 'PATCH',
        headers: {
          'x-auth-token': token,
        },
      });
      const updatedTemplate = await response.json();
      const newTemplates = templates.map((template) =>
        template.id === updatedTemplate.id ? updatedTemplate : template
      );
      setTemplates(newTemplates);
    } catch (error) {
      console.error('Error toggling delete status:', error);
    }
  };

  return (
    <div className="surgery-template">
      <h2>Surgery Consent Template Generator</h2>
      <div className="form-group">
        <label htmlFor="surgerySelect">Select Surgery</label>
        <select
          id="surgerySelect"
          value={selectedSurgeryId}
          onChange={handleSurgeryChange}
        >
          <option value="">-- Select Surgery --</option>
          {surgeries.map((surgery) => (
            <option key={surgery.id} value={surgery.id}>
              {surgery.name}
            </option>
          ))}
        </select>
      </div>

      {selectedSurgery && (
        <>
          <div className="form-group">
            <button className="generate-button" onClick={generateSurgeryConsent} disabled={isGenerating}>
              {isGenerating ? "Generating Consent Template..." : "Generate Consent Template"}
            </button>
          </div>

          {templateConsent && (
            <div className="template-consent">
              <div className="form-group">
                <label htmlFor="templateName">Template Name</label>
                <input
                  type="text"
                  id="templateName"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  placeholder="Enter template name"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="templateConsent">Template Consent</label>
              </div>
              <div className="form-group">
                <textarea
                  id="templateConsent"
                  value={templateConsent}
                  onChange={(e) => setTemplateConsent(e.target.value)}
                  rows="10"
                />
              </div>
              <div className="form-group">
              <div className="button-group">
                <button className="save-button" onClick={handleSaveTemplate}>
                  {editingIndex !== null ? 'Update' : 'Save'} Template
                </button>
                {editingIndex !== null && (
                  <button className="cancel-button" onClick={handleCancelEdit}>
                    Cancel
                  </button>
                )}
              </div>
              </div>
            </div>
          )}

          {templates.length > 0 && (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Template Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {templates.map((template, index) => (
                  <tr key={template.id} className={template.is_deleted ? 'deleted' : ''}>
                    <td>{template.name}</td>
                    <td>
                      <button className="edit-button" onClick={() => handleEditTemplate(index)} disabled={template.is_deleted}>
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button className="delete-button" onClick={() => handleToggleDelete(template.id)}>
                      <FontAwesomeIcon icon={template.is_deleted ? faCheckSquare : faTrashAlt } />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default SurgeryConsentTemplate;
