// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddSurgery from './pages/AddSurgery';
import SurgeryConsentTemplate from './pages/SurgeryConsentTemplate';
import GenerateConsent from './pages/GenerateConsent';
import Layout from './components/Layout';
import Logout from './pages/Logout';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import ManageUsers from './pages/ManageUsers';
import HipaaConsent from './pages/HipaaConsent';
import SurgeryConsent from './pages/SurgeryConsent';
import Team from './pages/Team'; // Import Team page
import TermsAndConditions from './pages/TnC'; // Import Terms and Conditions page
import Website from './pages/Website'; // Import Website page
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import Website page

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/team" element={<Team />} />
        <Route path="/tnc" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/" element={<Website />} />
        <Route path="/" element={<Layout />}>
        {/* Static Pages */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="add-surgery" element={<AddSurgery />} />
          <Route path="surgery-consent-template" element={<SurgeryConsentTemplate />} />
          <Route path="generate-consent" element={<GenerateConsent />} />
          <Route path="logout" element={<Logout />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="manage-users" element={<ManageUsers />} />
          <Route path="/user-consent/:hash_value/hipaa" element={<HipaaConsent />} />
          <Route path="/user-consent/:hash_value/surgery" element={<SurgeryConsent />} />
        </Route>
        <Route path="*" element={<Navigate to="/logout" replace />} />
      </Routes>
    </Router>
  );
}

export default App;

