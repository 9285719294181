import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import BermanImg from '../assets/Berman.jpg';
import ZahalskyImg from '../assets/Zahalsky.jpg';

function Team() {

  return (
    <div>
    <div className="header-container">
        <div className="logo" ></div>
        <div><h1>Dynamic Documents LLC</h1></div>
      </div>
    <div className='outlet-wrapper'>
      <nav class="navbar">
        <ul class="nav-links">
            <li><Link to="/">Home</Link></li>
            </ul>
        </nav>
      <h1>Founders and co-Presidents</h1>
      <h2>Alexa Zahalsky</h2>
      <div style={{display: "flex"}}>
        <h4>Alexa Zahalsky is a driven sophomore at North Broward Preparatory School in Parkland, Florida. Known for her academic curiosity and vibrant personality, Alexa thrives in her dynamic learning environment. She is a leader, actively engaging in both scholastic and extracurricular pursuits. A proud resident of Parkland, Alexa balances her studies with a love for community involvement and creative hobbies. Whether she’s exploring new interests or deepening her knowledge in the classroom, Alexa is focused on building a bright future filled with opportunity and success.</h4>
        <img 
        src={ZahalskyImg} 
        style={{ maxWidth: "50%", height: "250px", margin: "20px" }} 
        />
      </div>
      <h2>Sari Berman</h2>
      <div style={{display: "flex"}}>
        <h4>Sari Berman is a dedicated high school junior who balances a demanding academic schedule with her pursuits of improving the medical community through advanced artificial intelligence applications. Sari has an unyielding work ethic and consistently earns top marks in her honors and AP courses while also serving as a member of The Pingry School honor board. After school, she transforms into an avid runner, logging miles on the track or trails to train for her next big race. She’s a standout on the cross-country and track teams. Sari’s drive comes from her belief that hard work pays off, whether in the classroom or in her endeavors outside of school. Her peers admire her ability to stay grounded despite her busy schedule. To her, success is not just about winning races or acing tests but about making a meaningful impact.</h4>
        <img 
        src={BermanImg} 
        style={{ maxWidth: "50%", height: "250px", margin: "20px" }} 
        />
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Team;